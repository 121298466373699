/* eslint-disable import/no-extraneous-dependencies */
import A11yDialog from 'a11y-dialog';
import YouTubeIframeLoader from 'youtube-iframe';
import { Player } from '@vimeo/player';

export class Modal {
	modal: any;

	modalElement: HTMLElement;

	video?: any;

	constructor(modalElement: HTMLElement, container?: HTMLElement) {

		if (modalElement.id === 'login-modal') {
			return;
		}

		this.modal = new A11yDialog(modalElement, container);
		this.modalElement = modalElement;
		this.modal.on('show', () => {
			document.body.style.overflow = 'hidden';
			this.initVideo();
		});
		this.modal.on('hide', () => {
			document.body.style.overflow = '';
			this.pauseVideo();
		});
	}

	initVideo() {
		const video = this.modalElement.querySelector('[data-video-type]');
		if (video) {
			const videoType = video.getAttribute('data-video-type');
			if (videoType === 'vimeo') {
				this.vimeoInit();
			} else if (videoType === 'youtube') {
				this.youtubeInit();
			} else {
				this.nativeVideoInit();
			}
		}
	}

	vimeoInit() {
		const vimeoIframe = this.modalElement.querySelector('iframe');
		if (!vimeoIframe.src) {
			vimeoIframe.src = vimeoIframe.getAttribute('data-src');
			const vimeoPlayer = new Player(vimeoIframe, {});
			this.video = vimeoPlayer;
		}
	}

	youtubeInit() {
		const youtubeIframe = this.modalElement.querySelector('iframe');
		if (!youtubeIframe.src) {
			const uniqueID = this.modalElement.id;
			YouTubeIframeLoader.load(YT => {
				const playerID = `youtube-player-${uniqueID}`;
				youtubeIframe.id = playerID;
				youtubeIframe.src = youtubeIframe.getAttribute('data-src');
				const youtubePlayer = new YT.Player(playerID, {});
				this.video = youtubePlayer;
			});
		}
	}

	nativeVideoInit() {
		const videoElement = this.modalElement.querySelector('video');
		if (videoElement) {
			const videoSource = videoElement.querySelector('source');
			if (!videoSource.src) {
				videoSource.src = videoSource.getAttribute('data-src');
				videoElement.load();
			}
			this.video = videoElement;
		}
	}

	pauseVideo() {
		const videoContainer = this.modalElement.querySelector('[data-video-type]');
		if (videoContainer.getAttribute('data-video-type') === 'vimeo') {
			this.video.pause();
		} else if (videoContainer.getAttribute('data-video-type') === 'youtube') {
			this.video.pauseVideo();
		} else if (!this.video.paused) {
			this.video.pause();
		}
	}
}