import isTouchDevice from './is-touch';


export class mainNavigation {

    nav: HTMLElement;
    navContainer: HTMLElement;
    setForTouchscreen: Function;
    activeLink: HTMLElement;
    topNavs?: Array<any>;

    constructor(navigation: HTMLElement) {
        this.nav = navigation;
        this.activeLink = null;
        this.topNavs = this.nav && Array.prototype.slice.call(this.nav?.querySelectorAll('.cpgms-nav-meganav-item'));
        //setup callback function for if the device is a touch screen - 
        //first click just focuses to drop down the nav, 2nd click goes through
        this.setForTouchscreen = () => {
            this.topNavs?.forEach((link, index) => {
                let anchor = link.querySelector('a');
                anchor.addEventListener('click', e => {
                    e.preventDefault();
                    if (anchor === this.activeLink) {

                        window.location.href = anchor.href;
                    }
                    else {
                        this.activeLink = anchor;
                        link.classList.add('focused');
                    }
                })
            })
        }

        //deal with focus/keyboards     
        this.topNavs?.forEach((topNav) => {
            let childTags = Array.prototype.slice.call(topNav.querySelectorAll('a'));
            childTags.forEach((childTag) => {
                childTag.addEventListener('focus', () => {
                    if (!topNav.classList.contains('focused')) {
                        topNav.classList.add('focused');
                    }

                });
                childTag.addEventListener('blur', () => {
                    if (!topNav.contains(document.activeElement)) {
                        topNav.classList.remove('focused');
                    }
                });
            });

        });
        isTouchDevice(this.setForTouchscreen);


    }




}