import * as IMask from 'imask';

export function InitializeInputMasks() {
    var elements = document.querySelectorAll('[mask]');
    [].forEach.call(elements, (element, index) => {
        var mask = element.getAttribute('mask');
        if (mask) {
            var maskOptions = {
                mask: mask,
                lazy: false
            };
            var iMask = new IMask(element, maskOptions);

            // When user has entered no value, force the value back to an empty string to show default placeholder
            element.addEventListener('input', (e) => {
                if (e.target.value == mask.replace(/0/g, "_"))
                    e.target.value = "";
            });
            element.dispatchEvent(new Event('input'));
        }
    });
}