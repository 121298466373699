import { polyfill } from 'smoothscroll-polyfill';

// When the user scrolls down 20px from the top of the document, show the button
export function InitializeBackToTop() {
    polyfill();
    if (document.getElementById("backToTop") === null || document.getElementById("backToTop") === undefined)
        return;

    //smoothscroll.polyfill();
    window.addEventListener("scroll", function () {
        window.requestAnimationFrame(scrollFunction);
    });
    document.getElementById("backToTop").onclick = function () { topFunction() };
}

function scrollFunction() {
    var footerLinksHeight = document.getElementById('footer').getBoundingClientRect().height;
    var backToTop = document.getElementById("backToTop");
    var windowHeight = window.innerHeight || document.documentElement.clientHeight;
    var bottomOffset = 20;

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        //backToTop.className = " " + fadeInClass;
        backToTop.classList.add('active');
    } else {
        //backToTop.className = " " + fadeOutClass;
        backToTop.classList.remove('active')
    }

    // If scrolled to the point where the arrow would start to run into the footer
    if (window.pageYOffset + windowHeight + footerLinksHeight > document.body.scrollHeight) {
        backToTop.style.bottom = (windowHeight - document.getElementById('footer').getBoundingClientRect().top) + bottomOffset + "px";
    } else {
        backToTop.style.bottom = bottomOffset + "px";
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}