import { nodeListToArray } from '../helpers/nodeListToArray';

export function InitializeLoginForm() {
    let showPasswordCheckboxAttribute = '[data-show-password-checkbox]'
    let passwordFieldAttribute = '[data-password-field]'
    let checkboxes = nodeListToArray( document.querySelectorAll(showPasswordCheckboxAttribute));

    let passwordFields = nodeListToArray(document.querySelectorAll(passwordFieldAttribute));
    if (checkboxes != null && checkboxes.length > 0) {
        passwordFields.forEach(f => f instanceof HTMLInputElement ? new LoginForm(checkboxes[passwordFields.indexOf(f)], f) : null);
    }
}

export class LoginForm {
    passwordField: HTMLInputElement;
    checkbox: Element
    constructor(checkbox: Element, passwordField: HTMLInputElement) {
        this.passwordField = passwordField;
        this.checkbox = checkbox;
        this.init(checkbox, passwordField);
    }

    init(checkbox: Element, passwordField: Element) {
        checkbox.addEventListener('click', this.togglePassword)
    }

    togglePassword = () => {
        if (this.passwordField.type === "password") {
            this.passwordField.type = "text";
        } else {
            this.passwordField.type = "password";
        }
    }
}