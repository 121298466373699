/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { debounce } from 'lodash';
import { nodeListToArray } from '../helpers/nodeListToArray';
import { enableTabbableChildren } from '../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../helpers/disableTabbableChildren';
import '../helpers/closestPolyfill';

const accordionLists = nodeListToArray(
    document.querySelectorAll('[data-accordion-list]'),
);

class AccordionsOptions {
    oneOpenAtATime: boolean = true;
}

export class Accordion {
    options: AccordionsOptions = new AccordionsOptions();

    constructor(private optArg?: any) {
        window.addEventListener(
            'resize',
            debounce(() => {
                this.setAccordionHeights.bind(this);
            }, 50),
        );
        accordionLists.forEach(accordionList => {
            accordionList.addEventListener(
                'click',
                this.accordionClick.bind(this),
                false,
            );
            const panels = nodeListToArray(accordionList.querySelectorAll('[aria-hidden]'));
            panels.forEach(panel => {
                disableTabbableChildren(panel);
            });
        });
        //Check for Options
        if (this.optArg === undefined) this.optArg = new AccordionsOptions();
        else {
            this.options.oneOpenAtATime =
                this.optArg.oneOpenAtATime === undefined
                    ? this.options.oneOpenAtATime
                    : this.optArg.oneOpenAtATime;
        }
    }
    
    setAccordionHeights(){
        accordionLists.forEach(accordionList => {
            const openAccordions = nodeListToArray(accordionList.querySelectorAll('[aria-hidden="false"]'));
            if (openAccordions.length > 0) {
                openAccordions.forEach(openAccordion => {
                    const accordionToSize = openAccordion.querySelector(
                        '[data-accordion-content-id]',
                    );
                    const accordionNewHeight = accordionToSize.getBoundingClientRect()
                        .height;
                        
                    // eslint-disable-next-line no-param-reassign                    
                    openAccordion.setAttribute('style', `height:${  accordionNewHeight  }px`);
                });
            }
        });
    }

    accordionClick(e) {
        if (!e.target) return;

        if (e.target !== e.currentTarget) {
            let accordionToOpenId = e.target.getAttribute('aria-controls');
            if (accordionToOpenId === null) {
                accordionToOpenId = e.target.closest('[aria-controls]')?.getAttribute('aria-controls');
            }

            if (!accordionToOpenId) return;

            const accordionToToggle = document.querySelector(`#${accordionToOpenId}`);
            const accordionContentHeight = document.querySelector(`[data-accordion-content-id="${  accordionToOpenId  }"]`).getBoundingClientRect().height;
            if (this.options.oneOpenAtATime) {
                this.closeOpenAccordions(accordionToToggle);
            }
            if (accordionToToggle.getAttribute('aria-hidden') === 'false') {
                this.closeAccordion(accordionToToggle);
            } else {
                this.openAccordion(accordionToToggle, accordionContentHeight);
            }
            this.setAccordionHeights();
        }
        e.stopPropagation();
    }

    openAccordion(accordionToOpen, accordionToOpenHeight) {
        accordionToOpen.setAttribute('style', `height:${accordionToOpenHeight  }px`);
        accordionToOpen.setAttribute('aria-hidden', 'false');
        enableTabbableChildren(accordionToOpen);
        const { id } = accordionToOpen;
        document.querySelector(`[aria-controls="${id}"]`).setAttribute('aria-expanded', 'true');
    }

    closeAccordion(accordionToClose) {
        accordionToClose.setAttribute('style', 'height: 0px');
        accordionToClose.setAttribute('aria-hidden', 'true');
        disableTabbableChildren(accordionToClose);
        const { id } = accordionToClose;
        document.querySelector(`[aria-controls="${id}"]`).setAttribute('aria-expanded', 'false');
    }

    closeOpenAccordions(exception) {
        const container = exception.closest('ul');
        const openAccordions = nodeListToArray(container.querySelectorAll('[aria-hidden="false"]'));
        openAccordions.forEach(openAccordion => {
            if (openAccordion !== exception) {
                this.closeAccordion(openAccordion);
            }
        });
    }
}
