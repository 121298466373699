import { nodeListToArray } from '../helpers/nodeListToArray';

export function InitializeSidebar() {
    // Init Sidebar Nav
    let toggleAttribute = '[data-sidebar-toggle]';
    let toggleElements = nodeListToArray(document.querySelectorAll(toggleAttribute));
    toggleElements.forEach((block: HTMLElement, index) => {
        new SidebarToggle(block)
    });
}

export class SidebarToggle {
    toggle: HTMLElement;
    content: HTMLElement;
    activeClass: string = 'active';
    contentAttribute: string = 'data-sidebar-content';
    currentClass: string = 'current';
    parentElementSelector: string = ".cpg-sidebar-link";
    toggleAttribute: string = '[data-sidebar-toggle]';

    constructor(content: HTMLElement) {
        this.toggle = content;
        this.init(content);
    }

    init(content: HTMLElement) {
        var children = nodeListToArray(content.closest(this.parentElementSelector).childNodes) as HTMLElement[];
        if (children) {
            this.content = this.getContent(children);

            // If a page is a container, it doesn't have a url and won't get the current or active class applied in the html, so make sure we add it here
            if (children.some(child => this.childHasCurrentClass(child))) {
                if (!this.content.classList.contains(this.currentClass)) {
                    this.toggle.classList.add(this.currentClass);
                    (this.toggle.previousElementSibling as HTMLElement).classList.add(this.currentClass);
                    this.toggle.classList.add(this.activeClass);
                    this.content.classList.add(this.currentClass);
                    this.content.classList.add(this.activeClass);
                }
            }
        }

        if (this.content) {
            this.toggle.addEventListener('click', this.toggleClicked);
        }
        // Dont show toggle caret if there aren't additional items to show
        else
            this.toggle.style.display = 'none';
    }

    getContent = (elements: HTMLElement[]) => {
        return elements.filter(
            node => node.nodeType == 1 && node.getAttribute(this.contentAttribute) != null
        )[0] as HTMLElement;
    }

    hasCurrentClass = (element: HTMLElement) => {
        return element.nodeType == 1 && element.classList.contains(this.currentClass);
    }

    childHasCurrentClass = (child: HTMLElement) => {
        return this.hasCurrentClass(child) || (nodeListToArray(child.childNodes) as HTMLElement[]).some(childChild => this.childHasCurrentClass(childChild));
    }

    toggleClicked = (e: Event) => {
        if (e.target instanceof HTMLElement) {
            // Close all other toggles
            let toggleElements = nodeListToArray(document.querySelectorAll(this.toggleAttribute));
            toggleElements.forEach((element: HTMLElement, index) => {
                var children = nodeListToArray(element.closest(this.parentElementSelector).childNodes) as HTMLElement[];
                var content = this.getContent(children);
                content.classList.remove(this.activeClass);
                element.classList.remove(this.activeClass);
            });
            // Open this toggle
            this.content.classList.add(this.activeClass);
            this.toggle.classList.add(this.activeClass);
        }
    }
}