import { nodeListToArray } from '../helpers/nodeListToArray';

export function InitializeTabbedContent() {
    // Init Tabbed Blocks and Page
    let tabbedBlockClass = '.tabbed-block';
    let tabbedBlocks = nodeListToArray(document.querySelectorAll(tabbedBlockClass));
    tabbedBlocks.forEach((block: HTMLElement, index) => {
        new TabbedBlock(block)
    });

    let tabbedPageClass = '.tabbed-page';
    let tabbedPage = nodeListToArray(document.querySelectorAll(tabbedPageClass));
    tabbedPage.forEach((page: HTMLElement, index) => {
        new TabbedPage(page)
    });
}

export class TabbedContent {
    tabs: HTMLElement[];
    tabContentAreas: HTMLElement[];

    constructor(content: HTMLElement, tabClass: string, tabContentClass: string) {
        this.tabs = nodeListToArray(content.querySelectorAll(tabClass)) as HTMLElement[];
        this.tabContentAreas = nodeListToArray(content.querySelectorAll(tabContentClass)) as HTMLElement[];

        this.initTabs();
    }

    initTabs() {
        this.tabs.forEach((item) => {
            item.addEventListener('click', this.tabClicked)
        });

        this.showAndHideTabs(this.tabs[0]);
    }

    tabClicked = (e: Event) => {
        if (e.target instanceof HTMLElement) {
            this.showAndHideTabs(e.target);
        }
    }

    showAndHideTabs(tab: HTMLElement) {
        let highlightClass = 'highlight';

        // If the current tab is clicked add the needed highlight class
        this.tabs.forEach((t) => {
            if (t === tab)
                t.classList.add(highlightClass);
            else
                t.classList.remove(highlightClass);
        });

        // If the tab content data id matches the tab id that was clicked show that one and hide others
        this.tabContentAreas.forEach((t) => {
            if (t.dataset.tab === tab.dataset.tab)
                t.setAttribute('style', 'display:block');
            else
                t.setAttribute('style', 'display:none');
        });
    }
}

export class TabbedBlock extends TabbedContent {
    constructor(content: HTMLElement) {
        let tabClass = '.tabbed-block-tab';
        let contentClass = '.tabbed-block-content';
        super(content, tabClass, contentClass);
    }
}

export class TabbedPage extends TabbedContent {
    constructor(content: HTMLElement) {
        let tabClass = '.tabbed-page-tab';
        let contentClass = '.tabbed-page-content';
        super(content, tabClass, contentClass);
    }
}