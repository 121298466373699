export function InitializeOnThisPageBlocks() {
    let onThisPageBlockLists: HTMLCollection = document.getElementsByClassName('on-this-page-link-list-js');
    let sectionBlocks: NodeListOf<Element> = document.querySelectorAll('[data-id="section-anchor-link"]');

    if (sectionBlocks != null
        && sectionBlocks.length > 0
        && onThisPageBlockLists != null
        && onThisPageBlockLists.length > 0) {

        // Create list of links
        let ul = document.createElement('ul');

        // Add list item for each Section block on the page
        [].forEach.call(sectionBlocks, function (sectionBlock: HTMLElement) {
            if (sectionBlock.id) {
                ul.appendChild(getSectionBlockLinkListItem(sectionBlock));
            }
        });

        // Add link list to each On This Page block on the page
        [].forEach.call(onThisPageBlockLists, function (onThisPageBlock: HTMLElement) {
            onThisPageBlock.appendChild(ul);
        });
    }

    function getSectionBlockLinkListItem(sectionBlock: HTMLElement) {
        var li = document.createElement('li');
        var a = document.createElement('a');
        a.onclick = (e) => { scrollToView(sectionBlock.id) };
        a.text = sectionBlock.id;
        li.appendChild(a);
        
        return li;
    }

    function scrollToView(id: string) {
        document.getElementById(id).scrollIntoView(true);
    }
}
