import { nodeListToArray } from '../helpers/nodeListToArray';


export function globalAlert() {
    const dismissButtons = nodeListToArray(document.querySelectorAll("[data-alert-dismiss-id]")) as HTMLButtonElement[];
    const alerts = nodeListToArray(document.querySelectorAll("[data-alert-id]")) as HTMLElement[];
    dismissButtons.forEach(dismissButton => {
        dismissButton.addEventListener("click", () => {
            const matchingAlerts = alerts.filter((element) => {
                return element.getAttribute("data-alert-id") == dismissButton.getAttribute("data-alert-dismiss-id");
            });
            matchingAlerts.forEach(alert => {
                alert.setAttribute("aria-hidden", "true");
            });
           
        });
    });

}



