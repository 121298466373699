/* eslint-disable */

import * as _ from 'lodash';

export class UrlGallery {
    urls: string[] = [];
    activeUrl: string;
    galleryItemAttribute: string;
    galleryItemIdAttribute: string;
    galleryItemContentAttribute: string;
    galleryItemTriggerAttribute: string;
    galleryHtml: HTMLElement;
    galleryContentHtml: HTMLElement;
    pageBody: HTMLElement;
    activeClass: string = "active";
    scrollLockClass: string = "scroll-lock";

    get activeIndex() {
        if (!this.urls) {
            return -1;
        }

        var activeItem = this.urls.filter(item => item == this.activeUrl)[0];
        return this.urls.indexOf(activeItem);
    }

    constructor(...urlsToGrab: string[]) {
        this.initializeItems(urlsToGrab);
        this.initializeGallery();
    }

    initializeGallery = () => {
        this.pageBody = document.querySelector('body');
        this.galleryHtml = document.getElementById("generic-gallery");
        this.galleryContentHtml = document.getElementById("generic-gallery-content");

        var galleryClose = document.getElementById("generic-gallery-close");
        galleryClose?.addEventListener("click", (e) => {
            this.stopVideo();
            this.closeGallery();
        });
    }

    stopVideo = () => {
        let iframe: HTMLIFrameElement = this.galleryHtml.querySelector('iframe');
        if (iframe) {
            iframe.src = '';
        }
        
    }

    initializeItems = (urlsToGrab: string[]) => {
        let allAnchors = Array.prototype.slice.call(document.getElementsByTagName("a")) as HTMLElement[];
        var allFilteredAnchors = allAnchors.filter(
            a => urlsToGrab.some(url => a.getAttribute("href") && a.getAttribute("href").toLowerCase().indexOf(url.toLowerCase()) > -1));

        // bind all click events
        [].forEach.call(allFilteredAnchors, (anchor) => {
            anchor.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.openGallery((e.currentTarget as any).href);
            });
        });

        // only load distinct items
        for (var i = 0; i < allFilteredAnchors.length; i++) {
            if (!this.urls.some(url => url === allFilteredAnchors[i].getAttribute("href"))) {
                this.urls.push(allFilteredAnchors[i].getAttribute("href"));
            }
        }
    }

    renderActiveContent = () => {
        this.galleryContentHtml.setAttribute("src", this.activeUrl);
    }

    openGallery = (url: string) => {
        this.activeUrl = url;

        if (!this.galleryHtml.classList.contains(this.activeClass)) {
            this.galleryHtml.classList.add(this.activeClass);
            this.pageBody.classList.add(this.scrollLockClass);
            
        }

        this.renderActiveContent();
    }

    closeGallery = () => {
        if (this.galleryHtml.classList.contains(this.activeClass)) {
            this.galleryHtml.classList.remove(this.activeClass);
            this.pageBody.classList.remove(this.scrollLockClass);
        }
    }

    nextItem = () => {
        if (this.activeIndex > -1 && this.activeIndex + 1 < this.urls.length) {
            this.activeUrl = this.urls[this.activeIndex + 1];
        }
        this.renderActiveContent();
    }

    prevItem = () => {
        if (this.activeIndex > 0) {
            this.activeUrl = this.urls[this.activeIndex - 1];
        }
        this.renderActiveContent();
    }
}

