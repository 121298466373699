export function initializeMobileNav() {
    var mobileNavs = document.querySelectorAll('[data-nav-mobile]');
    [].forEach.call(mobileNavs, function (mobileNav) {
        initMobileNav(mobileNav);
    });
}

const OpenClass = "nav-open";

function initMobileNav(nav: HTMLElement) {
    var trigger: HTMLElement = nav.querySelector('[data-nav-mobile-trigger]');
    var panel: HTMLElement = nav.querySelector('[data-nav-mobile-panel]');

    var body: HTMLElement = document.body;

    trigger.addEventListener('click', () => {
        toggle(trigger);
        toggle(panel);

        toggle(body);
    });

    function toggle(elem: HTMLElement) {
        if (elem.classList.contains(OpenClass)) {
            elem.classList.remove(OpenClass);
           
        }
        else {
            elem.classList.add(OpenClass);
        }
    }
}