const TRACK_EXTERNAL_CLASS = 'trackExternal';

export function SendPageView(pageTitle: string, pagePath: string) {    

    (window as any).dataLayer?.push({
        'event': 'custom_pageView',
        'page_title': pageTitle,
        'page_path': pagePath,
    });
}

function SendEvent(category: string, label: string) {    

    (window as any).dataLayer?.push({
        'event': 'UAEvent',
        'event_action': 'click',
        'event_category': category,
        'event_label': label,
    });
}

function TrackPDF(e: Event) {
    const url = (e.currentTarget as any).getAttribute('href');
    if (!(url as any).startsWith('#') && (url as any).endsWith('.pdf')) {        
        SendEvent('PDF Link', url);
    }
}

function TrackExternal(e: Event) {
    const url = (e.currentTarget as any).getAttribute('href') as string;
    const siteUrl = window.location.host;
    if ((e.currentTarget as any).classList.contains(TRACK_EXTERNAL_CLASS)) {
        SendEvent('External Link', url);
    } else if (url.charAt(0) !== '/' && url.indexOf(siteUrl) === -1) {
        SendEvent('External Link', url); 
    }
}

function TrackMP3Clicks(e: Event) {
    const url = (e.currentTarget as any).getAttribute('href');
    if (!(url as any).startsWith('#') && (url as any).endsWith('.mp3')) {
        const mp3Title = url.split('/').pop();
        SendEvent('MP3 Play', url);
    }
}

function TrackMP3Plays(e: Event) {
    const url = (e.currentTarget as any).getAttribute('src') as string;
    if ((url as any).endsWith('.mp3')) {
        const mp3Title = url.split('/').pop();
        SendEvent('MP3 Play', url);
    }
}

function TrackInputClicks(e: Event) {
    const url = window.location.href;
    SendEvent('Form Submissions', url);
}

function TrackCourseClicks(e: Event) {
    const url = (e.currentTarget as any).getAttribute('href') as string;
    if (url != null && url.indexOf('learningcourses') !== -1 && (url as any).endsWith('.html')) {
        SendEvent('Launch Course Clicked', url);
    }
}

function InitializeOutboundLinks() {
    const allAnchors = Array.prototype.slice.call(document.getElementsByTagName('a'));
    [].forEach.call(allAnchors, (anchor: HTMLAnchorElement) => {
        anchor.addEventListener('click', (e: Event) => {
            TrackPDF(e);
            TrackExternal(e);
            TrackMP3Clicks(e);
            TrackCourseClicks(e);
        });
    });
}

function InitializeAudioPlays() {
    const allAnchors = Array.prototype.slice.call(document.getElementsByTagName('audio'));
    [].forEach.call(allAnchors, (anchor: HTMLAudioElement) => {
        anchor.addEventListener('play', (e: Event) => {
            TrackMP3Plays(e);
        });
    });
}

function InitializeInputSubmitClicks() {
    const allInputs = Array.prototype.slice.call(document.getElementsByTagName('input'));
    [].forEach.call(allInputs, (input: HTMLInputElement) => {
        if (input.type === 'submit') {
            input.addEventListener('click', (e: Event) => {
                TrackInputClicks(e);
            });
        }
        
    });
}

function InitializeButtonSubmitClicks() {
    const allInputs = Array.prototype.slice.call(document.getElementsByTagName('button'));
    [].forEach.call(allInputs, (button: HTMLButtonElement) => {
        if (button.classList.contains('FormSubmitButton')) {
            button.addEventListener('click', (e: Event) => {
                TrackInputClicks(e);
            });
        }
    });
}

export function InitializeAnalytics() {
    InitializeOutboundLinks();
    InitializeAudioPlays();
    InitializeInputSubmitClicks();
    InitializeButtonSubmitClicks();
}
