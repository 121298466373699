export function initializePageSelect() {
    let triggers = document.querySelectorAll('[data-page-select-container]');

    [].forEach.call(triggers, (trigger) => new PageSelect(trigger));

}

const AriaExpanded = 'aria-expanded';
const AriaHidden = 'aria-hidden';
class PageSelect {
    trigger: HTMLButtonElement;
    selectPanel: HTMLElement;
    container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.trigger = container.querySelector('[data-page-select-trigger]');
        this.selectPanel = container.querySelector('[data-page-select-panel]');

        this.initialize();
      
    }

    initialize = () => {
        if (!this.trigger || !this.selectPanel) return;

        window.addEventListener('click', (e) => this.handleOutsideClick(e));

        this.trigger.addEventListener('click', this.handleTriggerClick);
    }

    handleTriggerClick = () => {
        if (this.trigger.getAttribute(AriaExpanded) === 'true' && this.selectPanel.getAttribute(AriaHidden) === 'false') {
            this.close();
        }
        else {
            this.open();
        }
       
    }

    handleOutsideClick = (e: Event) => {
        if (!this.container.contains(e.target as HTMLElement)) {
            this.close();
        }
    }

    close = () => {
        this.trigger.setAttribute(AriaExpanded, 'false');
        this.selectPanel.setAttribute(AriaHidden, 'true');
    }

    open = () => {
        this.trigger.setAttribute(AriaExpanded, 'true');
        this.selectPanel.setAttribute(AriaHidden, 'false');
    }
}